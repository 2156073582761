import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accountTypeOption', 'organizationNameInput']

  connect() {
    this.toggleOrganizationNameInput();
  }

  toggleOrganizationNameInput() {
    let visible = false;

    this.accountTypeOptionTargets.forEach(element => {
      if (element.checked && element.value != 'individual') {
        visible = true;
      }
    });

    if (visible) {
      this.organizationNameInputTarget.classList.remove('d-none')
      this.organizationNameInputTarget.required = true
    } else {
      this.organizationNameInputTarget.classList.add('d-none')
      this.organizationNameInputTarget.required = false
    }
  }
}
